<template>
  <div>
    <base-header class="pb-1">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Nova Assinatura Pessoa Jurídica</h6>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">

      <form class="needs-validation" @submit.prevent="salvarAssinatura">
        <!-- Titular da Assinatura -->
        <card>
          <h3 slot="header" class="mb-0">Dados da Empresa</h3>
          <div class="form-row">
            <div class="col-md-4">
              <base-input label="CNPJ" name="cpfcnpj" placeholder="CNPJ" required v-model="subscription.holder.cpfCnpj"
                v-mask="['##.###.###/####-##']">
              </base-input>
            </div>
            <div class="col-md-8">
              <base-input label="Razão Social" name="nomecompleto" placeholder=" Razão Social" required
                v-model="subscription.holder.name">
              </base-input>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-4">
              <base-input label="E-mail" name="email" placeholder="E-mail" v-model="subscription.holder.email"
                type="email">
              </base-input>
            </div>
            <div class="col-md-5">
              <base-input label="Tel Celular" name="celular" placeholder="Tel Celular" required
                v-model="subscription.holder.cellPhone" v-mask="['(##)#####-####']">
              </base-input>
            </div>
            <div class="col-md-3">
              <base-input label="Tel Comercial" name="homePhone" placeholder="Tel Res/Com"
                v-model="subscription.holder.homePhone" v-mask="['(##)####-####', '(##)#####-####']">
              </base-input>
            </div>
          </div>
        </card>

        <div class="row">
          <!-- Endereco -->
          <div class="col-lg-6">
            <div class="card-wrapper">
              <card>
                <h3 slot="header" class="mb-0">Endereço da Empresa</h3>
                <div class="row">
                  <div class="col-md-6">
                    <base-input append-icon="fas fa-map-marker" name="cep" label="CEP" placeholder="CEP" required
                      v-model="subscription.holder.zipcode" v-mask="'########'" @blur="validateZipcode">
                    </base-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <base-input label="Endereço" name="endereco" placeholder="Endereço" required
                      v-model="subscription.holder.address">
                    </base-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <base-input :disabled="isDisabled" label="Número" name="numero" id="numero" required
                      placeholder="Número" v-model="subscription.holder.number">
                    </base-input>
                  </div>
                  <div class="col-md-1">
                    <div class="form-group">
                      <label class="form-control-label">S/N</label>
                      <div class="custom-switch">
                        <input type="checkbox" @change="semNumero()" class="custom-control-input" id="antigas" />
                        <label class="custom-control-label form-control-label" for="antigas">Ativar</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <base-input label="Complemento" name="complemento" placeholder="Complemento"
                      v-model="subscription.holder.complementAddress">
                    </base-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <base-input label="UF" name="uf" placeholder="UF" required v-model="subscription.holder.uf">
                    </base-input>
                  </div>
                  <div class="col-md-8">
                    <base-input label="Cidade" name="cidade" placeholder="Cidade" required
                      v-model="subscription.holder.city">
                    </base-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <base-input label="Bairro" name="bairro" placeholder="Bairro" required
                      v-model="subscription.holder.neighborhood">
                    </base-input>
                  </div>
                </div>
              </card>
            </div>
          </div>

          <!-- Informações do Plano -->
          <div class="col-lg-6">
            <div class="card-wrapper">
              <card>
                <h3 slot="header" class="mb-0">Informações da Assinatura</h3>
                <div class="row">
                  <div class="col-md-12">
                    <base-input label="Plano" required name="plano">
                      <el-select v-model="subscription.planId" filterable placeholder="Plano">
                        <el-option v-for="option in plans" :key="option.id" :label="option.name" :value="option.id">

                          <span style="float: left">
                            {{ option.name }}
                            <span style="color: #8492a6; font-size: 10px">
                              Adesão {{ option.accessionFeeFmt }} / Titular {{ option.priceHolderFmt }} / Dependente
                              {{ option.priceDependentFmt }}
                            </span>
                          </span>
                        </el-option>
                      </el-select>
                    </base-input>
                  </div>
                  <!--
                      <div class="col-md-6">
                          <base-input label="Dia de Vencimento" required name="diadevencimento">
                            <el-select v-model="subscription.dayForPayment" placeholder="Dia de Vencimento">
                              <el-option v-for="option in paymentDays"
                                        :key="option.label"
                                        :label="option.label"
                                        :value="option.value">
                              </el-option>
                            </el-select>
                          </base-input>
                      </div>
                      -->
                  <div class="col-md-12" v-if="getProfile() != 'BROKER'">
                    <base-input label="Corretor">
                      <el-select v-model="subscription.brokerId" placeholder="Corretor" name="corretor">
                        <el-option v-for="option in brokers" :key="option.name" :label="option.name" :value="option.id">
                        </el-option>
                      </el-select>
                    </base-input>
                  </div>
                  <div class="col-md-12" v-if="getProfile() != 'BROKER'">
                    <base-input label="Filial">
                      <el-select v-model="subscription.companyId" placeholder="Filial" name="filial">
                        <el-option v-for="option in companies" :key="option.name" :label="option.name"
                          :value="option.id">
                        </el-option>
                      </el-select>
                    </base-input>
                  </div>
                </div>
              </card>
            </div>
          </div>
        </div>

        <div class="col-md-12 text-center mb-3">
          <base-button type="primary" @click="salvarAssinatura">Solicitar Assinatura</base-button>
        </div>

      </form>
    </div> <!--- fecha conteudo -->



  </div>
</template>
<script>
import { Select, Option } from 'element-ui'
import { Table, TableColumn, Tooltip } from 'element-ui'
import { load } from 'recaptcha-v3'
import Vue from 'vue'

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  created() {
    load("6LeDu_IpAAAAAGawg8nR3SFA_ZYpSwsC71Ub1f_U").then((instance) => {
      Vue.prototype.$recaptcha = instance;
    });

    this.loadPlans()
    this.loadBrokers()
    this.loadCompanies()
  },
  data() {
    return {
      isDisabled: false,
      beginSaveProcess: false,
      plans: [],
      paymentTypes: [],
      paymentDays: [
        { label: 'Dia 5', value: '5' },
        { label: 'Dia 10', value: '10' },
        { label: 'Dia 15', value: '15' },
        { label: 'Dia 20', value: '20' },
        { label: 'Dia 25', value: '25' }
      ],
      brokers: [],
      companies: [],
      validated: false,
      subscription: {
        brokerId: null,
        companyId: null,
        planId: null,
        dayForPayment: null,
        paymentType: 'TICKET',
        type: 'PJ',
        holderOnlyResponsibleFinance: true,
        holder: {
          id: null,
          cpfCnpj: '',
          name: '',
          dateOfBirth: '',
          sex: 'MALE',
          email: '',
          cellPhone: '',
          homePhone: '',
          zipcode: '',
          address: '',
          complementAddress: '',
          uf: '',
          city: '',
          neighborhood: '',
          isHolder: true
        }
      }
    };
  },
  methods: {

    semNumero() {
      let el = document.getElementById("numero");
      if (document.getElementById("antigas").checked) {
        document.getElementById("numero").isDisabled = true;
        this.isDisabled = true;
        el.value = "0";
        el.dispatchEvent(new Event("input"));
      } else {
        document.getElementById("numero").isDisabled = false;
        this.isDisabled = false;
        el.value = "";
        el.dispatchEvent(new Event("input"));
      }
    },

    getProfile() {
      return localStorage.getItem("profile")
    },
    salvarAssinatura() {
      try {
        this.$validator.validateAll()
      } finally {
        this.validated = true;
      }

      if (this.validated) {
        this.beginSaveProcess = true
        this.$recaptcha.execute("newsubscriptionpj").then((token) => {
          const request = {
            recaptchaResponse: token,
            ...this.subscription
          }

          this.$clubApi.put('/subscription', request)
            .then((response) => {
              location.href = '/assinatura/' + response.data.object.id
            }).catch((error) => {
              this.$notify({ type: 'warning', message: error.response.data.msg })
            }).finally(() => {
              this.beginSaveProcess = false
              NProgress.done()
            })
        });
      }
    },
    loadPlans() {
      this.$clubApi.get('/plan/list/active/PJ')
        .then((response) => {
          this.plans = response.data.object
        }).catch(() => {
          this.$notify({ type: 'warning', message: 'Erro ao carregar listagem de planos disponiveis.' })
        }).finally(() => {
          NProgress.done()
        })
    },
    loadBrokers() {
      this.$clubApi.get('/broker')
        .then((response) => {
          this.brokers = response.data.object
        }).catch(() => {
          this.$notify({ type: 'warning', message: 'Erro ao carregar listagem de corretores disponiveis.' })
        })
    },
    loadCompanies() {
      this.$clubApi.get('/company')
        .then((response) => {
          this.companies = response.data.object
        }).catch(() => {
          this.$notify({ type: 'warning', message: 'Erro ao carregar listagem de companias disponiveis.' })
        }).finally(() => {
          NProgress.done()
        })
    },
    validateZipcode() {
      var zipcode = this.subscription.holder.zipcode.replace("-", "")

      if (zipcode == "") {
        this.subscription.holder.zipcode = "";
        this.subscription.holder.address = "";
        this.subscription.holder.uf = "";
        this.subscription.holder.city = "";
        this.subscription.holder.neighborhood = "";
        this.subscription.holder.complementAddress = "";
      }
      else {
        this.$clubApi.get('/utils/zipcode/' + zipcode)
          .then((response) => {
            this.subscription.holder.address = response.data.object.logradouro;
            this.subscription.holder.uf = response.data.object.uf;
            this.subscription.holder.city = response.data.object.localidade;
            this.subscription.holder.neighborhood = response.data.object.bairro;
          }).catch((error) => {
            this.subscription.holder.zipcode = "";
            this.subscription.holder.address = "";
            this.subscription.holder.uf = "";
            this.subscription.holder.city = "";
            this.subscription.holder.neighborhood = "";
            this.subscription.holder.complementAddress = "";
            this.$notify({ type: 'warning', message: error.response.data.msg })
          }).finally(() => {
            NProgress.done()
          })
      }
    }
  }
};
</script>
<style></style>
